import EnquiryForm from "./forms/enquiry"
import React from "react"
import { dark_blue } from "@css/colours"
import { page_wrapper } from "@css/global"
import sanitizeHtml from "sanitize-html"
import styled from "styled-components"

const Form = props => {
  const forms = {
    enquiry: EnquiryForm,
    default: <></>,
  }

  const FormWrapper = forms[props?.formSelection]
    ? forms[props?.formSelection]
    : forms["default"]

  return (
    <Wrapper backgroundColour={props?.backgroundColour}>
      <header className="form__title">
        <h2>{props?.formTitle}</h2>
      </header>
      <div>
        {props?.formIntro !== null && (
          <div
            className="form__intro"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(props?.formIntro),
            }}
          ></div>
        )}
        <FormWrapper
          formSuccess={props?.formSuccess}
          accentColour={props?.formAccentColour}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${page_wrapper};

  display: grid;

  .swiper-slide {
    background-color: ${({ backgroundColour }) =>
      backgroundColour ? backgroundColour : dark_blue};
  }

  @media (min-width: 0em) {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 1.5rem;
  }
  @media (min-width: 75em) {
    grid-template-columns: minmax(0, 1.5fr) minmax(0, 5fr);
    grid-gap: 3rem;
  }

  .form__title {
    padding-top: 0.3125rem;
    h2 {
      font-size: 0.9375rem;
      @media (min-width: 48em) {
        font-size: 0.9rem;
      }

      font-weight: 500;
      letter-spacing: 0.4px;
    }
  }

  .form__intro {
    margin-bottom: 2rem;

    * {
      line-height: 2.2rem;
    }

    @media (min-width: 48em) {
      width: 65%;
      * {
        font-size: 1.8rem;
        line-height: 2.5625rem;
      }
    }
  }
`

export default Form
