import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"

const Icon = ({ isScrolled }) => {
  const { wp } = useStaticQuery(graphql`
    query IconQuery {
      wp {
        headerSettings {
          headerFields {
            headerLogo {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper isScrolled={isScrolled} className="icon__wrapper">
      <img
        src={wp?.headerSettings?.headerFields?.headerLogo?.localFile?.publicURL}
        alt={wp?.headerSettings?.headerFields?.headerLogo?.altText}
      />
      <h2>Kell Moore</h2>
    </Wrapper>
  )
}

export default Icon

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  position: fixed;
  bottom: 3rem;
  left: 3rem;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s;

  ${({ isScrolled }) =>
    isScrolled &&
    css`
      opacity: 1;
      transition: opacity 0.5s;
    `}

  h2 {
    opacity: 0;
    transition: opacity 0.4s;
    white-space: nowrap;

    font-size: 1.8rem;
    @media (min-width: 48em) {
      font-size: var(--fontSize-4);
    }

    @media (min-width: 48em) and (max-width: 74.999em) {
      display: none;
    }
  }

  img {
    height: auto;
    @media (min-width: 0em) {
      width: 3rem;
      margin-right: 0.75rem;
    }
    @media (min-width: 48em) {
      margin-right: 1rem;
      width: 2rem;
      bottom: 3rem;
      left: 3rem;
    }
    @media (min-width: 75em) {
      width: 3rem;
    }
  }
`
