import React from "react"
import styled from "styled-components"
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import sanitizeHtml from "sanitize-html"
import { mid_blue } from "@css/colours"

const SubAccordion = props => {
  return (
    <Wrapper className="employees-grid__sub-accordion">
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <span>{props?.title}</span>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(props.body),
            }}
          ></div>
        </AccordionItemPanel>
      </AccordionItem>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-right: 0.1rem;

  * {
    font-size: 0.875rem;
  }

  .accordion__button {
    color: ${mid_blue};
    border-bottom: 1px solid ${mid_blue};
    cursor: pointer;
    font-weight: 600;
    font-family: var(--fontFamily-link);
    letter-spacing: 0.025rem;
    line-height: 2;

    &[aria-expanded="true"] {
      span {
        &:after {
          display: inline-block;
          content: "-";
        }
      }
    }

    span {
      &:after {
        content: "+";
        margin-left: 0.5rem;
      }

      display: inline-block;
    }
  }

  .accordion__panel {
    padding: 1.5rem 0;

    li {
      padding-bottom: 0.25rem;
    }
  }
`

export default SubAccordion
