import React, { useState } from "react"
import { useForm } from "@formspree/react"
import styled from "styled-components"
import { dark_blue } from "@css/colours"
import { CaretRight } from "@styled-icons/boxicons-regular/CaretRight"
import Spinner from "../spinner/spinner"
import sanitizeHtml from "sanitize-html"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"

const EnquiryForm = props => {
  const [state, handleSubmit] = useForm(process.env.GATSBY_FORMSPREE_ENDPOINT)
  const [swiper, setSwiper] = useState(null)

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        enquiry: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string().max(65, "Max 65 characters").required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        enquiry: Yup.string().min(1).required("Required"),
      })}
      onSubmit={handleSubmit}
    >
      {({ validateForm, setTouched, validateField }) => (
        <>
          {state.succeeded && (
            <Success>
              {props.formSuccess !== null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(props.formSuccess),
                  }}
                ></div>
              ) : (
                <h2>Thanks for your enquiry. We'll be in touch.</h2>
              )}
            </Success>
          )}

          {!state.succeeded && (
            <Wrapper accentColour={props.accentColour}>
              <Form>
                <Swiper
                  onSwiper={setSwiper}
                  modules={[EffectFade]}
                  effect="fade"
                  noSwiping={true}
                  allowTouchMove={false}
                >
                  <SwiperSlide>
                    <div className="enquiry__fields">
                      <div className="enquiry__field">
                        <Field
                          name="name"
                          type="text"
                          placeholder="Full Name*"
                        />
                        <ErrorMessage
                          component="div"
                          className="field__error"
                          name="name"
                        />
                      </div>

                      <div className="enquiry__field">
                        <Field name="email" placeholder="Email*" />
                        <ErrorMessage
                          component="div"
                          className="field__error"
                          name="email"
                        />
                      </div>

                      <div className="enquiry__field">
                        <Field as="select" name="area">
                          <option value="None Selected">
                            Area of Enquiry +
                          </option>
                          <option value="Conveyancing">Conveyancing</option>
                          <option value="Local Government + Planning">
                            Local Government + Planning
                          </option>
                          <option value="Property + Development">
                            Property + Development
                          </option>
                          <option value="Wills, Estate and Probate">
                            Wills, Estate and Probate
                          </option>
                          <option value="Business + Company">
                            Business + Company
                          </option>
                          <option value="Commercial Litigation">
                            Commercial Litigation
                          </option>
                          <option value="Estate Litigation">
                            Estate Litigation
                          </option>
                          <option value="Building + Construction">
                            Building + Construction
                          </option>
                          <option value="Employment Law">Employment Law</option>
                          <option value="Other">Other</option>
                        </Field>
                      </div>
                    </div>

                    <div className="enquiry__controls">
                      <p>1 — 2</p>
                      <button
                        type="button"
                        key="progress"
                        aria-label="Click this button to progress to the next section of the form"
                        onClick={() => {
                          validateForm().then(values => {
                            setTouched(values)
                            const { name, email } = values
                            if (!name && !email) {
                              swiper.slideNext()
                            }
                          })
                        }}
                      >
                        <span> Next </span>
                        <span>
                          {" "}
                          <CaretRight />{" "}
                        </span>
                      </button>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="enquiry__field">
                      <Field
                        name="enquiry"
                        type="text"
                        placeholder="Details of enquiry*"
                      />
                      <ErrorMessage
                        component="div"
                        className="field__error"
                        name="enquiry"
                      />
                    </div>

                    <div className="enquiry__controls">
                      <p>2 — 2</p>
                      <button type="submit" disabled={state.submitting}>
                        <>
                          {state.submitting && (
                            <Spinner accentColour={props.accentColour} />
                          )}
                          {!state.submitting && (
                            <>
                              <span>Submit</span>
                              <span>
                                <CaretRight />
                              </span>
                            </>
                          )}
                        </>
                      </button>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </Form>
            </Wrapper>
          )}
        </>
      )}
    </Formik>
  )
}

const Success = styled.div`
  margin-bottom: 1.5rem;
`

const Wrapper = styled.div`
  *,
  ::placeholder {
    color: ${props =>
      props.accentColour !== null ? props.accentColour : dark_blue};
  }

  button {
    font-weight: 600;
  }

  .swiper-slide {
    height: auto;
    .enquiry__fields {
      display: grid;
      grid-gap: 0.5rem;

      @media (min-width: 75em) {
        grid-gap: 1rem;
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    .field__error {
      font-size: 0.75rem;
      margin-top: 0.5rem;
    }

    .enquiry__field {
      position: relative;

      select {
        appearance: none;
      }

      input,
      select {
        &:focus {
          outline-color: ${props =>
            props.accentColour !== null ? props.accentColour : dark_blue};
        }
        ::placeholder {
          color: ${props =>
            props.accentColour !== null ? props.accentColour : dark_blue};
        }

        height: 2.25em;
        font-weight: 600;
        border: 0;
        padding: 0.25rem 0;
        border-bottom: 1px solid
          ${props =>
            props.accentColour !== null ? props.accentColour : dark_blue};
        font-size: 0.8rem;
        display: flex;
        border-radius: 0;

        @media (min-width: 0em) {
          width: 100%;
          margin-top: 0.25rem;
        }
        @media (min-width: 48em) {
          flex: 1;
          margin-top: 0;
        }

        background: transparent;
      }
    }

    .enquiry__controls {
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p,
      button {
        font-weight: 600;
        font-size: 0.75rem;
      }
      button {
        margin-top: 0;
      }
    }
  }

  button {
    cursor: pointer;
    margin-top: 1.5rem;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    padding-left: 0;
    padding-right: 0;

    svg {
      display: inline-block;
      height: 1.25em;
    }
  }
`

export default EnquiryForm
