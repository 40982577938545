import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import sanitizeHtml from "sanitize-html"
import { page_wrapper, animated_link } from "@css/global"

const CallToAction = props => {
  const [dropdown, showDropdown] = useState(false)

  return (
    <Wrapper {...props}>
      {props?.ctaTitle !== null && <h2>{props.ctaTitle}</h2>}

      <div>
        {props?.ctaMessage !== null && (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(props?.ctaMessage),
            }}
            className="call-to-action__message"
          ></div>
        )}

        {(props?.ctaColumnOne !== null || props?.ctaColumnTwo !== null) && (
          <div className="call-to-action__columns">
            {props.ctaColumnOne !== null && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(props?.ctaColumnOne),
                }}
              ></div>
            )}
            {props.ctaColumnTwo !== null && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(props?.ctaColumnTwo),
                }}
              ></div>
            )}
          </div>
        )}

        {props.linkType === "link" && (
          <Link className="call-to-action__link" to={props.ctaLink.url}>
            {props.ctaLink.title}
          </Link>
        )}

        {props.linkType === "dropdown" && (
          <>
            <button
              className="call-to-action__button call-to-action__dropdown"
              onClick={() => {
                showDropdown(!dropdown)
              }}
            >
              <span>{dropdown ? "Read Less -" : "Read More +"}</span>
            </button>

            <div
              className={
                dropdown
                  ? "call-to-action__dropdown-body call-to-action__dropdown-body--show"
                  : "call-to-action__dropdown-body"
              }
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(props.dropdownContent),
              }}
            ></div>
          </>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.article`
  ${page_wrapper};

  display: grid;

  @media (min-width: 0em) {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: 1.5rem;
  }
  @media (min-width: 75em) {
    grid-template-columns: minmax(0, 1.5fr) minmax(0, 5fr);
    grid-gap: 3rem;
  }

  > h2 {
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.4px;
  }

  p {
    font-size: 0.9375rem;
    line-height: 1.4375rem;
  }

  .call-to-action__message {
    margin-bottom: 2rem;

    @media (min-width: 64em) {
      width: 70%;
    }

    * {
      line-height: 1.9rem;
      font-size: 1.4rem;

      @media (min-width: 48em) {
        line-height: 2.5rem;
        font-size: 1.8rem;
      }
    }
  }

  .call-to-action__columns {
    display: grid;

    @media (min-width: 0em) {
      grid-gap: 1.5rem;
      grid-template-columns: minmax(0, 1fr);
    }
    @media (min-width: 48em) {
      grid-gap: 3rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .call-to-action__button,
  .call-to-action__link {
    color: ${props =>
      props.ctaLinkColour !== null ? props.ctaLinkColour : "white"};
    font-weight: 600;

    font-size: 0.875rem;
    @media (min-width: 48em) {
      font-size: 0.9375rem;
    }

    display: flex;
    align-items: center;
    margin-top: 2rem;
    cursor: pointer;
    &:before,
    &:after {
      background: ${props =>
        props.ctaLinkColour !== null ? props.ctaLinkColour : "white"};
    }
  }

  ${animated_link};

  .call-to-action__button {
    background: transparent;
    border: 0;
    padding: 0;

    span {
      &:nth-of-type(2) {
        font-size: 1.25rem;
        margin-left: 0.25rem;
      }
    }
  }

  .call-to-action__dropdown-body {
    display: none;

    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    &.call-to-action__dropdown-body--show {
      display: block;
      animation: fadeIn 0.4s;
    }

    @media (min-width: 64em) {
      max-width: calc(50% - 3rem);
    }
  }
`

export default CallToAction
