import { dark_blue } from "@css/colours"

export const mobile_styles = `

    @media (min-width:0em) and (max-width:47.999em){
        padding:7.5rem 1.5rem 3rem 1.5rem;
        width:100vw;
        transform:translateX(+100vw);
    }
    @media (min-width:48em) and (max-width:74.999em){
        padding:7.5rem 3rem 3rem 3rem;
        width:50vw;
        transform:translateX(-50vw);
    }

    @media (max-width:74.999em){
        position:fixed;
        top:0;
        left:0;
        height:100vh;
        transition:transform 0.5s;
        background:${dark_blue};
        
        a{
            display:block;
            font-size:1.5rem;
            margin-bottom:0.5rem;
            margin-right:0;
            width:max-content;
        }
    }

    

`
