import { Footer, Header, Icon } from "@components"
import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"

import Sticky from "react-stickynode"

const Layout = ({ hasHero, children, pageSlug, is404 }) => {
  const layoutData = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  const [scrolled, setScrolled] = useState()

  useEffect(() => {
    window.onscroll = () => {
      if (window?.pageYOffset > 100) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
  }, [])

  return (
    <PageWrapper>
      {layoutData?.wp?.headerSettings?.headerFields?.headerLogo !== null && (
        <Sticky top={0} enabled={true} bottomBoundary=".footer__end-anchor">
          <Icon isScrolled={scrolled} />
        </Sticky>
      )}

      <main id={pageSlug}>
        {!is404 && <Header scrolled={scrolled} />}
        <Wrapper
          itemScope
          itemType="http://schema.org/Article"
          hasHero={hasHero}
          className="layout__wrapper"
        >
          {children}
        </Wrapper>
        {!is404 && <Footer />}
      </main>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  display: flex;

  main {
    max-width: 100%;
  }

  @media (min-width: 0em) {
    > .sticky-outer-wrapper {
      display: none;
    }
  }
  @media (min-width: 48em) {
    > .sticky-outer-wrapper {
      display: block;
      height: 100vh;
      &.released {
        h2 {
          opacity: 1;
          transition: opacity 0.5s;
        }
      }
      .sticky-inner-wrapper {
        height: 100%;
      }
    }
  }
`
const Wrapper = styled.article`
  @media (min-width: 48em) {
    padding-left: 8.5rem;
  }
  @media (min-width: 75em) {
    padding-top: 9.0625rem;
    padding-left: calc(4.5rem + 158px);
  }

  ${props =>
    !props.hasHero &&
    css`
      @media (max-width: 47.999em) {
        padding-top: 8.5rem;
      }
    `}
`

export default Layout
