import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import { EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import { page_wrapper } from "@css/global"

const ImageBlock = props => {
  SwiperCore.use([Autoplay])

  return (
    <Wrapper>
      <div className="image-block__spacer"></div>

      {props.images.length === 1 && (
        <GatsbyImage
          image={getImage(props.images[0]?.localFile)}
          alt={props?.images[0]?.altText}
        />
      )}
      {props.images.length > 1 && (
        <Swiper
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[EffectFade]}
          effect="fade"
        >
          {props?.images?.map((image, imdex) => {
            return (
              <SwiperSlide>
                <GatsbyImage
                  image={getImage(image?.localFile)}
                  alt={image?.altText}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.figure`
  ${page_wrapper};

  display: grid;
  grid-gap: 3rem;

  @media (min-width: 0em) {
    grid-template-columns: minmax(0, 1fr);
  }
  @media (min-width: 75em) {
    grid-template-columns: minmax(0, 1.5fr) minmax(0, 5fr);
  }

  .gatsby-image-wrapper {
    height: 0;
    @media (min-width: 0em) {
      padding-bottom: 162%;
    }
    @media (min-width: 48em) {
      padding-bottom: 62%;
    }
  }
  .swiper {
    width: 100%;
  }

  .image-block__spacer {
    @media (max-width: 75em) {
      display: none;
    }
  }
`

export default ImageBlock
