import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import SubAccordion from "./sub-accordion/sub-accordion"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import sanitizeHtml from "sanitize-html"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { page_wrapper } from "@css/global"
import { sortBy } from "lodash"
import { mid_blue } from "@css/colours"
import StickyBox from "react-sticky-box"

const EmployeesGrid = props => {
  const [activeEmployee, setActiveEmployee] = useState()
  const [swiper, setSwiper] = useState(null)

  useEffect(() => {
    swiper?.slideTo(activeEmployee)
  }, [activeEmployee, swiper])

  return (
    <Wrapper className="employeees-grid__wrapper">
      <StickyBox className="employees-grid__sticky-accordion" offsetTop={48}>
        <Accordion
          allowMultipleExpanded={false}
          allowZeroExpanded={true}
          onChange={uuid => {
            setActiveEmployee(
              uuid.length
                ? parseInt(uuid[0]?.replace(/employee-/gi, ""))
                : undefined
            )
          }}
        >
          {sortBy(props?.edges, ({ node }) => node?.menuOrder || 0).map(
            (employee, index) => {
              return (
                <AccordionItem
                  key={index}
                  uuid={`employee-${index}`}
                  dangerouslySetExpanded={
                    activeEmployee === index ? true : false
                  }
                  onClick={() => {
                    document
                      ?.getElementById(employee?.node?.slug)
                      ?.scrollIntoView({
                        behavior: "smooth",
                      })
                  }}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton
                      className={
                        activeEmployee === index
                          ? `accordion__button accordion__button--active`
                          : `accordion__button`
                      }
                    >
                      {employee?.node?.title}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {employee?.node?.featuredImage !== null && (
                      <GatsbyImage
                        className="employees-grid__mobile-image"
                        image={getImage(
                          employee?.node?.featuredImage?.node?.localFile
                        )}
                        alt={employee?.node?.featuredImage?.node?.altText}
                      />
                    )}
                    {employee?.node?.featuredImage === null && (
                      <div className="employees-grid__mobile-image employees-grid__placeholder"></div>
                    )}
                    <article>
                      <h2>{employee?.node?.employeesFields?.jobTitle}</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitizeHtml(
                            employee?.node?.employeesFields?.biography
                          ),
                        }}
                      ></div>
                    </article>
                    <Accordion
                      allowMultipleExpanded={false}
                      allowZeroExpanded={true}
                    >
                      {employee?.node?.employeesFields?.practiceAreas !==
                        null && (
                        <SubAccordion
                          title="Practice Areas"
                          body={employee?.node?.employeesFields?.practiceAreas}
                        />
                      )}
                      {employee?.node?.employeesFields?.recentWork !== null && (
                        <SubAccordion
                          title="Recent Work"
                          body={employee?.node?.employeesFields?.recentWork}
                        />
                      )}
                      {employee?.node?.employeesFields?.qualifications !==
                        null && (
                        <SubAccordion
                          title="Qualifications"
                          body={employee?.node?.employeesFields?.qualifications}
                        />
                      )}
                      {employee?.node?.employeesFields?.admissions !== null && (
                        <SubAccordion
                          title="Admissions"
                          body={employee?.node?.employeesFields?.admissions}
                        />
                      )}
                      {employee?.node?.employeesFields?.memberships !==
                        null && (
                        <SubAccordion
                          title="Memberships"
                          body={employee?.node?.employeesFields?.memberships}
                        />
                      )}
                    </Accordion>
                  </AccordionItemPanel>
                </AccordionItem>
              )
            }
          )}
        </Accordion>
      </StickyBox>

      <Swiper
        onSwiper={setSwiper}
        allowTouchMove={false}
        modules={[EffectFade]}
        effect="fade"
        autoHeight={true}
      >
        {sortBy(props?.edges, ({ node }) => node?.menuOrder || 0).map(
          (employee, index) => {
            return (
              <SwiperSlide key={index}>
                {employee?.node?.featuredImage !== null && (
                  <GatsbyImage
                    image={getImage(
                      employee?.node?.featuredImage?.node?.localFile
                    )}
                    alt={employee?.node?.featuredImage?.node?.altText}
                  />
                )}
                {employee?.node?.featuredImage === null && (
                  <div className="employees-grid__placeholder"></div>
                )}
              </SwiperSlide>
            )
          }
        )}
      </Swiper>
      <div className="employees-grid__staff-gallery">
        {sortBy(props?.edges, ({ node }) => node?.menuOrder || 0).map(
          ({ node }, index) => {
            return (
              <button
                id={node?.slug}
                key={index}
                onClick={() => {
                  setActiveEmployee(
                    (activeEmployee !== undefined &&
                      activeEmployee !== index) ||
                      activeEmployee === undefined
                      ? index
                      : undefined
                  )
                }}
                aria-label={`Click this image of ${node?.title} to view their biography.`}
              >
                <>
                  {node?.featuredImage !== null && (
                    <GatsbyImage
                      image={getImage(node?.featuredImage?.node?.localFile)}
                      alt={node?.featuredImage?.node?.altText}
                    />
                  )}
                  {node?.featuredImage === null && (
                    <div className="employees-grid__placeholder"></div>
                  )}

                  <span
                    className={
                      index === activeEmployee
                        ? "employees-grid__overlay employees-grid__overlay--active"
                        : "employees-grid__overlay"
                    }
                  ></span>
                </>
              </button>
            )
          }
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${page_wrapper};

  display: grid;

  .employees-grid__sticky-accordion {
    @media (max-width: 74.999em) {
      position: static !important;
    }

    height: max-content;

    > .accordion {
      > .accordion__item {
        > .accordion__heading {
          .accordion__button {
            border-bottom: 1px solid white;
            cursor: pointer;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.7;
            letter-spacing: 0.2px;

            &.accordion__button--active {
              color: ${mid_blue};
              border-bottom: 1px solid ${mid_blue};
            }
          }
        }
        > .accordion__panel {
          padding: 1.5rem 0rem;

          > article {
            margin-bottom: 1.5rem;

            h2 {
              font-size: 1.1rem;
              font-weight: 600;
              margin-top: 0;
              margin-bottom: 1rem;
            }

            p {
              font-size: 0.9375rem;
              line-height: 1.4375rem;
              margin: 0.25rem 0;
            }
          }

          .employees-grid__mobile-image {
            margin-bottom: 1.5rem;

            @media (min-width: 48em) {
              display: none;
            }
          }
        }
      }
    }
  }

  .swiper {
    max-width: 100%;
  }

  .gatsby-image-wrapper,
  .employees-grid__placeholder {
    height: 0;
    padding-bottom: 100%;
  }

  .employees-grid__placeholder {
    background: grey;
  }

  @media (min-width: 0em) {
    grid-template-columns: minmax(0, 1fr);

    .swiper {
      display: none;
    }
  }
  @media (min-width: 48em) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 3rem;

    .swiper {
      display: block;
    }
  }
  @media (min-width: 75em) {
    .swiper {
      display: none;
    }

    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }
  @media (min-width: 87.5em) {
    grid-template-columns: minmax(0, 1.5fr) minmax(0, 5fr);
    grid-gap: 3rem;

    .swiper {
      display: none;
    }
  }

  .employees-grid__staff-gallery {
    @media (min-width: 0em) {
      display: none;
    }
    @media (min-width: 48em) {
      grid-auto-rows: minmax(min-content, max-content);
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 75em) {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    button {
      &:hover {
        .employees-grid__overlay {
          opacity: 0.65;
          transition: opacity 0.4s;
        }
      }
      height: max-content;
      cursor: pointer;
      border: 0;
      padding: 0;
      background: transparent;
      position: relative;

      .employees-grid__overlay {
        background: rgba(45, 60, 85);
        position: absolute;
        inset: 0;
        opacity: 0.8;
        transition: opacity 0.4s;

        &.employees-grid__overlay--active {
          opacity: 0;
          transition: opacity 0.4s;
        }
      }
    }
  }
`

export default EmployeesGrid
