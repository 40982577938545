import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import EmployeesGrid from "./employees-grid/employees-grid"
import EmployeesCarousel from "./employees-carousel/employees-carousel"

const Staff = props => {
  /**
   * Filters allEmployees based on those selected from the Wordpress backend.
   * @param {*} allWpEmployee - a GraphQL query result of all of our employees
   * @param {*} employeesFilter - an array from Wordpress of selected employees we want to show
   * @returns array of filtered employees
   */
  function filterEmployees(allWpEmployee, employeesFilter) {
    return {
      edges: allWpEmployee?.edges.filter(employee => {
        return employeesFilter.map(e => e.slug).includes(employee?.node?.slug)
      }),
    }
  }

  const { allWpEmployee } = useStaticQuery(graphql`
    query EmployeesQuery {
      allWpEmployee {
        edges {
          node {
            title
            slug
            menuOrder
            employeesFields {
              admissions
              biography
              jobTitle
              memberships
              practiceAreas
              qualifications
              recentWork
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {props.fieldGroupName.includes("EmployeesGrid") && (
        <EmployeesGrid
          backgroundColour={props.backgroundColour}
          {...allWpEmployee}
        />
      )}
      {props.fieldGroupName.includes("EmployeesCarousel") && (
        <EmployeesCarousel
          backgroundColour={props.backgroundColour}
          blockIntro={props.blockIntro}
          employees={
            props.employeesSelect === "all"
              ? allWpEmployee
              : filterEmployees(allWpEmployee, props.employees)
          }
        />
      )}
    </>
  )
}

export default Staff
