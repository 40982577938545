import React, { useState } from "react"
import styled from "styled-components"
import sanitizeHtml from "sanitize-html"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { EffectFade } from "swiper"
import "swiper/css/effect-fade"
import { mid_blue, dark_blue } from "@css/colours"
import { page_wrapper, animated_link } from "@css/global"
import EmployeesCarousel from "@blocks/employees/employees-carousel/employees-carousel"
import { sortBy } from "lodash"

const Services = props => {
  const [swiper, setSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const { allWpService, wpPage } = useStaticQuery(graphql`
    query ServicesQuery {
      wpPage(title: { eq: "Our People" }) {
        uri
      }

      allWpService {
        edges {
          node {
            title
            slug
            menuOrder
            servicesFields {
              serviceDescription
              serviceEmployees {
                ... on WpEmployee {
                  id
                  title
                  slug
                  employeesFields {
                    jobTitle
                  }
                  featuredImage {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            width: 800
                            placeholder: BLURRED
                            formats: [AUTO, WEBP]
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper background={props.servicesBackgroundColour}>
      {allWpService.edges.length && (
        <>
          <div className="services__services-list">
            {sortBy(allWpService?.edges, ({ node }) => node.menuOrder || 0).map(
              (service, index) => {
                return (
                  <button
                    key={index}
                    onClick={() => {
                      swiper && swiper.slideTo(index)
                    }}
                    className={index === activeIndex ? "active" : ""}
                  >
                    {service?.node?.title}
                  </button>
                )
              }
            )}
          </div>

          <Swiper
            allowTouchMove={false}
            onSwiper={setSwiper}
            onSlideChange={swiper => {
              setActiveIndex(swiper.activeIndex)
            }}
            modules={[EffectFade]}
            effect="fade"
            autoHeight={true}
          >
            {sortBy(
              allWpService?.edges,
              ({ node }) => node.menuOrder || 0
            )?.map((service, index) => {
              /**
               * A little bit of data-processing here.
               * This allows us to reuse our employees carousel component if we structure the data how it requires it to be.
               */
              const employees = {
                edges: service?.node?.servicesFields?.serviceEmployees?.map(
                  (s, i) => {
                    return { node: s }
                  }
                ),
              }

              return (
                <SwiperSlide key={index}>
                  {service?.node?.servicesFields?.serviceDescription !==
                    null && (
                    <div
                      className="services__description"
                      dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                          service?.node?.servicesFields?.serviceDescription
                        ),
                      }}
                    ></div>
                  )}
                  {employees.edges && (
                    <div className="services__employees">
                      <EmployeesCarousel
                        carouselTitle={`${service?.node?.title} staff`}
                        employees={employees}
                      />

                      {wpPage !== null && (
                        <Link to={wpPage?.uri}>More about our people</Link>
                      )}
                    </div>
                  )}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.article`
  display: grid;

  @media (min-width: 0em) {
    grid-gap: 1.5rem;
    grid-template-columns: minmax(0, 1fr);
  }
  @media (min-width: 64em) {
    grid-gap: 3rem;
    grid-template-columns: minmax(0, 1.5fr) minmax(0, 5fr);
  }

  .services__services-list {
    @media (min-width: 0em) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    @media (min-width: 48em) {
      padding-left: 0;
      padding-top: 6rem;
      padding-right: 3rem;
    }

    button {
      cursor: pointer;
      display: block;
      width: 100%;
      text-align: left;
      margin-bottom: 0.25rem;
      background: transparent;
      color: ${mid_blue};
      border: 0;
      border-bottom: 1px solid ${mid_blue};
      padding-left: 0;
      padding-right: 0;
      padding-top: 0.625rem;
      padding-bottom: 0.5rem;
      transition: color 0.4s, border-color 0.4s;

      font-size: 0.9375rem;
      @media (min-width: 48em) {
        font-size: 1.25rem;
      }

      font-weight: 500;
      line-height: 1.1;
      letter-spacing: 0.2px;

      &.active {
        color: white;
        border-color: white;
        transition: color 0.4s, border-color 0.4s;
      }
    }
  }

  > .swiper {
    max-width: 100%;

    > .swiper-wrapper {
      > .swiper-slide {
        p,
        li {
          font-size: 0.9375rem;
          line-height: 1.4375rem;
        }

        @media (min-width: 0em) {
          padding-top: 4.5rem;
          padding-bottom: 4.5rem;
        }
        @media (min-width: 64em) {
          padding-top: 6rem;
          padding-bottom: 6rem;
        }

        .services__description {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-size: 1.4rem;
            line-height: 1.9rem;
            @media (min-width: 48em) {
              font-size: var(--fontSize-4);
            }
          }
          @media (min-width: 48em) {
            padding-right: 12rem;
          }
        }

        .services__employees {
          ${animated_link};

          a {
            color: ${dark_blue};
            font-size: 0.9375rem;
            display: block;
            margin-top: 3rem;
            margin-bottom: 3rem;

            &:after,
            &:before {
              background: ${dark_blue};
            }
          }
        }

        .employees-carousel {
          margin-top: 4.5rem;

          padding: 0;
          display: block;

          .employees-carousel__intro {
            display: none;
          }

          .employees-carousel__swiper {
            .employees-carousel__swiper-title {
              display: flex;

              .employees-carousel__navigation {
                margin-top: 0;
              }
            }
          }
        }
        background-color: ${props =>
          props.background !== null ? props.background : mid_blue};
        ${page_wrapper};
        @media (min-width: 48em) {
          padding-left: 3rem;
          padding-right: 3rem;
        }
        @media (min-width: 75em) {
          padding-left: 4.5rem;
          padding-right: 4.5rem;
        }
      }
    }
  }
`

export default Services
