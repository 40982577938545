import { mid_blue } from "@css/colours"

export const page_wrapper = `
    @media (min-width:0em){
        padding-left:1.5rem;
        padding-right:1.5rem;
    }
    @media (min-width:48em){
        padding-left:3rem;
        padding-right:3rem;
    }
`

/**
 * A specific version of the page wrapper without any left padding up to 48em.
 * This is useful for elements that don't have a background colour so we can maximise their width on mobile.
 */
export const page_wrapper_inner = `
    @media (min-width:0em){
        padding-left:1.5rem;
        padding-right:1.5rem;
    }
    @media (min-width:48em){
        padding-left:3rem;
        padding-right:3rem;
    }
    @media (min-width:64em){
        padding-left:4.5rem;
        padding-right:4.5rem;
    }
`

export const animated_link = `
    a{
        width:max-content;
        align-items:center;
        position:relative;
        color:${mid_blue}; 
        font-size:1.15rem;
        letter-spacing:-0.75px;

        &:before{
            content:'';
            position:absolute;
            bottom:-0.15rem;
            left:0;
            width:0;
            height:1px;
            background:${mid_blue};
            transition:width 0.4s;
        }

        &:after{
            display:inline-block;
            width:0.85em;
            height:0.85em;
            margin-bottom:-0.175rem;
            margin-left:0.5rem;
            content:''; 
            opacity:1;
            transition:opacity 0.4s;
            background:${mid_blue};
            clip-path: polygon(50% 50%, 0 0, 0 100%);
        }

        &:hover{
            &:before{
                width:100%;
                transition:width 0.4s;
            }

            &:hover{
                opacity:0.8;
                transition:opacity 0.4s;
            }
        }
    }
`
