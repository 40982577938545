import React from "react"
import sanitizeHtml from "sanitize-html"
import styled from "styled-components"
import { animated_link, page_wrapper } from "@css/global"

const Text = props => {
  return (
    <Wrapper>
      <div
        className="text__intro"
        dangerouslySetInnerHTML={{
          __html: props.intro !== null ? sanitizeHtml(props.intro) : "",
        }}
      ></div>
      <div
        className="text__content"
        dangerouslySetInnerHTML={{
          __html:
            props?.textContent !== null ? sanitizeHtml(props.textContent) : "",
        }}
      ></div>
    </Wrapper>
  )
}

const Wrapper = styled.article`
  ${page_wrapper}

  display:grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 1.5rem;

  @media (min-width: 48em) {
    grid-template-columns: minmax(0, 1.5fr) minmax(0, 5fr);
    grid-gap: 3rem;

    .text__intro {
      text-align: right;
    }
  }

  .text__intro {
    * {
      font-size: 0.9375rem;
      @media (min-width: 48em) {
        font-size: 1.5rem;
      }

      line-height: 2rem;
    }
    padding-top: 0.3125rem;
  }

  .text__content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.8rem;
      line-height: 2.5625rem;
    }
  }

  ${animated_link};
`

export default Text
