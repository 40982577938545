import React, { useRef } from "react"
import styled, { css } from "styled-components"
import {
  CallToAction,
  Employees,
  Form,
  Hero,
  ImageBlock,
  Offices,
  Services,
  Text,
} from "@components"
import { useInViewport } from "react-in-viewport"

const Block = ({ blockData }) => {
  const blockRef = useRef()
  const blockType = blockData.fieldGroupName

  const { inViewport, enterCount } = useInViewport(
    blockRef,
    {
      /** Because the services block and staff grid is pretty tall, we give it a much smaller threshold */
      threshold:
        blockData.fieldGroupName ===
        ("Page_Pageblocks_PageBlocks_ServicesBlock" ||
          "Page_Pageblocks_PageBlocks_EmployeesGrid")
          ? 0.1
          : 0.25,
    },
    { disconnectOnLeave: true }
  )

  /**
   * Default component
   */
  const Default = () => <div>Hmm - there is no content yet for this page</div>

  const blocks = {
    Page_Pageblocks_PageBlocks_CallToAction: CallToAction,
    Page_Pageblocks_PageBlocks_EmployeesCarousel: Employees,
    Page_Pageblocks_PageBlocks_EmployeesGrid: Employees,
    Page_Pageblocks_PageBlocks_Form: Form,
    Page_Pageblocks_PageBlocks_Hero: Hero,
    Page_Pageblocks_PageBlocks_Image: ImageBlock,
    Page_Pageblocks_PageBlocks_Offices: Offices,
    Page_Pageblocks_PageBlocks_ServicesBlock: Services,
    Page_Pageblocks_PageBlocks_TextBlock: Text,
    page_default: Default,
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const BlockTag = blocks[blockType]
    ? blocks[blockType]
    : blocks["page_default"]

  const blockName = blockData?.fieldGroupName
    ?.replace("Page_Pageblocks_PageBlocks_", "")
    .toLowerCase()

  return (
    <BlockWrapper
      id={blockData.blockId !== null ? blockData?.blockId : null}
      className={
        inViewport || enterCount >= 1
          ? `block__wrapper--entered ${blockName}`
          : `${blockName}`
      }
      ref={blockRef}
      {...blockData}
    >
      <BlockTag {...blockData} />
    </BlockWrapper>
  )
}

const BlockWrapper = styled.section`
  opacity: 0;

  &.block__wrapper--entered {
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }

  @keyframes fadeInUp {
    from {
      transform: translate3d(0, 3rem, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  ${props => {
    if (props.paddingTop !== null && props.paddingBottom !== null) {
      return css`
        padding-top: ${props.paddingTop}rem;
        padding-bottom: ${props.paddingBottom}rem;
      `
    } else {
      return css`
        padding-top: 3rem;
        padding-bottom: 3rem;
      `
    }
  }}

  ${props => {
    if (props.backgroundColour) {
      return css`
        background-color: ${props.backgroundColour};
      `
    }
  }}
`

export default Block
