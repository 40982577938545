import React from "react"
import styled from "styled-components"
import { dark_blue } from "@css/colours"

const Spinner = props => {
  return (
    <Wrapper
      accentColour={
        props.accentColour !== null ? props.accentColour : dark_blue
      }
    >
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 3.5rem;
    height: 0.5rem;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 50%;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: ${props => props.accentColour};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 0.5rem;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 0.5rem;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 1.5rem;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 2.5rem;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(1rem, 0);
    }
  }
`

export default Spinner
