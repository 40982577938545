import React from "react"
import styled from "styled-components"
import { Offices } from "@components"
import { page_wrapper } from "@css/global"
import { mid_blue } from "@css/colours"

const Footer = () => {
  return (
    <>
      <Wrapper>
        <div className="footer__end-anchor"></div>
        <div className="footer__copyright">
          <span>
            Liability limited by a scheme approved under Professional Standards
            Legislation.
            <br />
            {`© ${new Date().getFullYear()} Kell Moore Laywers & Conveyancers. `}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://kindredesign.com.au"
            >
              Website by Kindred.
            </a>
          </span>
        </div>
        <div className="footer__offices">
          <div className="footer__spacer"></div>
          <Offices />
        </div>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.footer`
  position: relative;
  ${page_wrapper};

  display: flex;

  @media (min-width: 0em) {
    flex-wrap: wrap;
  }
  @media (min-width: 48em) {
    flex-wrap: nowrap;
  }

  padding-top: 10rem;
  padding-bottom: 3rem;

  .footer__end-anchor {
    position: absolute;
    width: 100%;

    @media (min-width: 48em) {
      top: calc(15rem);
    }
    @media (min-width: 75em) {
      top: calc(15.75rem);
    }
    @media (min-width: 87.5em) {
      top: 16.5rem;
    }
  }

  .footer__copyright {
    display: flex;
    align-items: flex-end;

    @media (min-width: 0em) {
      width: 100%;
      order: 2;
      margin-top: 1.5rem;

      text-align: center;

      span {
        width: 100%;
      }

      a {
        margin-top: 0.75rem;
        transition: color 0.4s;
        position: relative;
        z-index: 3;

        &:hover {
          color: ${mid_blue};
          transition: color 0.4s;
        }
      }
    }
    @media (min-width: 48em) {
      br {
        display: block;
      }

      text-align: left;

      order: unset;
      margin-top: 0;
      width: calc(136px - 3rem);
      position: relative;
      span {
        width: calc(100% + 7.5rem);
      }

      a {
        margin-top: 0;
      }
    }
    @media (min-width: 75em) {
      span {
        width: calc(100% + 4.5rem);
      }
      width: calc(252px - 4.5rem);
    }
    @media (min-width: 87.5em) {
      span {
        max-width: 50%;
      }

      position: static;
    }

    font-size: 0.625rem;

    span {
      @media (min-width: 48em) {
        position: absolute;
      }

      p,
      a {
        display: inline-block;
      }
    }
  }

  .footer__offices {
    position: relative;
    z-index: 1;
    ${page_wrapper};
    padding-right: 0 !important;
    flex: 1;

    display: grid;

    @media (max-width: 47.999em) {
      padding-left: 0.001rem !important;
    }

    @media (min-width: 0em) {
      grid-template-columns: minmax(0, 1fr);
    }
    @media (min-width: 48em) {
      grid-template-columns: minmax(0, 1.5fr) minmax(0, 5fr);
      grid-gap: 3rem;
    }

    .offices {
      padding-left: 0;
      padding-right: 0;

      a,
      p {
        font-size: 1.1rem !important;
      }

      .offices__office-title {
        margin-bottom: 0 !important;
        font-size: 0.95rem !important;
        letter-spacing: 0.2px;

        @media (min-width: 87.5em) {
          padding-bottom: 0.75rem;
        }
      }

      .offices__offices {
        .accordion {
          &:not(:last-of-type) {
            @media (min-width: 87.5em) {
              margin-right: 4.5rem;
            }
          }
        }
      }

      .offices__office--exchange,
      .offices__office--pobox,
      .offices__office--fax,
      .offices__office--phone span:nth-of-type(1) {
        display: none !important;
      }

      .offices__office--phone {
        margin-top: 0.25rem !important;
      }
    }
  }
`

export default Footer
