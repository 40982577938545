import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectFade } from "swiper"
import "swiper/css"
import "swiper/css/effect-fade"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { dark_blue } from "@css/colours"
import { page_wrapper } from "@css/global"
import { animated_link } from "@css/global"
import SwiperCore, { Autoplay } from "swiper"

const Hero = props => {
  SwiperCore.use([Autoplay])
  const [swiper, setSwiper] = useState(null)

  return (
    <Wrapper
      onClick={() => {
        swiper.slideNext()
      }}
    >
      <Swiper
        loop={true}
        onSwiper={setSwiper}
        modules={[EffectFade]}
        effect="fade"
        autoplay={{ delay: 6500 }}
        navigation={{
          nextEl: ".next",
        }}
      >
        {props?.heroSlides?.map((slide, index) => {
          return (
            <SwiperSlide key={index} tag="figure">
              <GatsbyImage
                image={getImage(slide?.heroImage?.localFile)}
                alt={slide?.heroImage?.altText}
              />

              {(slide?.heroCaption !== null || slide?.heroLink) !== null && (
                <figcaption className="hero__caption">
                  {slide?.heroCaption !== null && <h2>{slide?.heroCaption}</h2>}
                  {slide?.heroLink !== null && (
                    <Link to={slide?.heroLink?.url}>
                      {slide?.heroLink?.title}
                    </Link>
                  )}
                </figcaption>
              )}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </Wrapper>
  )
}

const Wrapper = styled.figure`
  position: relative;

  .swiper,
  .swiper-wrapper,
  .swiper-slide {
    height: 100%;
  }

  .swiper-slide {
    max-width: 100% !important;
    width: 100% !important;
    background: ${dark_blue};
  }

  .gatsby-image-wrapper {
    height: 50vh;
    @media (min-width: 48em) {
      margin-left: 6rem;
      height: 100vh;
    }
    @media (min-width: 64em) {
      margin-left: 9rem;
    }
    @media (min-width: 75em) {
      height: calc(100vh - 9.0625rem);
    }
  }

  .hero__caption {
    ${page_wrapper};
    display: block;
    width: 100%;
    padding: 2rem 0;

    @media (min-width: 48em) {
      padding: 0;
      top: unset;
      position: absolute;
      bottom: 3rem;
      left: 0;
      z-index: 1;
    }

    h2 {
      font-size: 1.7rem;
      line-height: 2.2rem;
      @media (min-width: 48em) {
        font-size: 2.2rem;
        line-height: 1.3;
      }
    }

    ${animated_link}

    a {
      display: block;
      width: max-content;
      margin-top: 2rem;
      font-family: var(--fontFamily-sans);
      color: white;
      letter-spacing: -0.2px;

      &:before,
      &:after {
        background: white;
      }

      font-size: 0.9375rem;

      @media (min-width: 48em) {
        margin-bottom: 1rem;
        font-size: 1.03125rem;
      }
    }

    @media (min-width: 48em) {
      max-width: 85%;
    }
    @media (min-width: 75em) {
      max-width: 60%;
    }
  }
`

export default Hero
