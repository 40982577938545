import { Link, graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { dark_blue, mid_blue } from "@css/colours"
import styled, { css } from "styled-components"

import { Icon } from "@components"
import { Link as ScrollLink } from "react-scroll"
import { mobile_styles } from "./styles/mobile-styles"
import { page_wrapper } from "@css/global"
import { useLocation } from "@reach/router"

const Header = ({ scrolled }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation()

  /**
   *
   * @param {*} uri - the uri/path provided from the wordpress menu
   * @returns bool - if uri from the menu is the current page or not.
   */
  function isCurrentPage(uri) {
    if (uri?.split("#")[0] === location.pathname) return true
    return false
  }

  useEffect(() => {
    document?.body?.classList?.toggle("noscroll", menuOpen)
  }, [menuOpen])

  const { wpMenu } = useStaticQuery(graphql`
    query NavQuery {
      wpMenu(slug: { eq: "navigation-menu" }) {
        id
        menuItems {
          nodes {
            path
            label
          }
        }
      }
    }
  `)

  return (
    <Wrapper isScrolled={scrolled} menuOpen={menuOpen}>
      <Link
        to="/"
        aria-label="Click this link to return to the home page"
        className="header__logo"
      >
        <Icon />
      </Link>

      <button
        className="header__menu-toggle"
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
        aria-label="Click this button to open/close the menu"
      >
        <span></span>
        <span></span>
        <span></span>
      </button>

      <div className="header__menu">
        {wpMenu?.menuItems?.nodes?.map((menu_item, index) => {
          if (
            menu_item?.path?.includes("#") &&
            isCurrentPage(menu_item?.path)
          ) {
            return (
              <ScrollLink
                key={index}
                to={menu_item?.path.replace(/\/#/gi, "")}
                smooth={true}
                duration={500}
                className="header__link"
                onClick={() => {
                  setMenuOpen(false)
                }}
              >
                {menu_item.label}
              </ScrollLink>
            )
          } else
            return (
              <Link
                key={index}
                to={menu_item.path}
                className="header__link"
                activeClassName="header__link--active"
                onClick={() => {
                  setMenuOpen(false)
                }}
              >
                {menu_item.label}
              </Link>
            )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  ${page_wrapper}
  position:relative;
  overflow-y: visible;

  @media (max-width: 47.999em) {
    &:after {
      position: absolute;
      left: 0;
      bottom: -10px;
      content: "";
      height: 10px;
      background: linear-gradient(
        180deg,
        rgba(17, 17, 17, 0.75) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      opacity: 0;
      transition: opacity 0.4s;
      ${props => props.isScrolled && `opacity:1;`}
    }
  }

  padding-top: 1.6rem;
  padding-bottom: 0.9rem;

  @media (min-width: 48em) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 0em) {
    width: 100%;
  }
  @media (min-width: 48em) {
    width: ${props =>
      props.isScrolled && !props.menuOpen ? "max-content" : "100%"};
  }

  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  background: transparent;
  transition: background 0.5s 0.1s;

  .header__link {
    margin-right: 2rem;
    color: white;
    cursor: pointer;

    &.header__link--active {
      color: ${mid_blue};
    }
  }

  .header__menu-toggle {
    z-index: 2;
    transform: rotate(0deg);
    transition: transform 0.2s;
    border: 0;
    background: transparent;
    color: white;
    width: 2rem;
    height: 1.25rem;
    cursor: pointer;
    padding: 0;

    ${props =>
      props.menuOpen &&
      css`
        transform: rotate(90deg);
      `}

    @media (min-width:48em) {
      position: fixed;
      left: 3rem;
      top: 3rem;
    }
    @media (min-width: 75em) {
      opacity: 0;
      transition: opacity 0.1s;
      left: 3rem;
      top: 4rem;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 0.1875rem;
      width: 100%;
      background: white;

      &:nth-of-type(1) {
        top: 0;
      }
      &:nth-of-type(2) {
        top: 50%;
      }
      &:nth-of-type(3) {
        top: 100%;
      }
    }
  }

  .icon__wrapper {
    position: static;
    opacity: 1;

    h2 {
      opacity: 1;
    }

    @media (min-width: 0em) {
      font-size: 1.3rem;
    }
    @media (min-width: 48em) {
      display: none;
    }
    @media (min-width: 75em) {
      display: flex;
      transform: translateY(0%);
      transition: transform 0.5s;
    }
  }

  .header__menu {
    transform: translateY(0%);
    transition: transform 0.5s;

    a {
      font-size: 0.9rem;
      font-family: var(--fontFamily-sans);
    }

    ${mobile_styles};

    @media (min-width: 0em) and (max-width: 47.999em) {
      ${props =>
        props.menuOpen &&
        `
                transform:translateX(0);
                transition:transform 0.5s;
            `}
    }
    @media (min-width: 48em) and (max-width: 74.999em) {
      ${props =>
        props.menuOpen &&
        `
                transform:translateX(0);
                transition:transform 0.5s;
            `}
    }

    @media (min-width: 75em) {
      margin-left: 0;
      position: absolute;
      padding-top: 0.25rem;
      left: calc(4.5rem + 158px + 9rem);
      margin-right: 3em;
    }
  }

  ${props =>
    props.isScrolled &&
    css`
      @media (min-width: 0em) {
        background: ${dark_blue};
      }
      @media (min-width: 48em) {
        background: transparent;
      }

      @media (min-width: 75em) {
        .header__logo {
          transform: translateY(-500%);
          transition: transform 0.5s;
        }

        .header__menu {
          transform: translateY(-500%);
          transition: transform 0.5s;
        }

        .header__menu-toggle {
          opacity: 1;
          transition: opacity 0.1s, transform 0.2s;
        }
      }
    `}

  ${props =>
    props.menuOpen &&
    props.isScrolled &&
    `

        @media (min-width:75em){
            background:#0c1118;
            transition:background 0.1s 0.1s;
    
            .header__menu{
                transform:translateY(0);
                transition:transform 0.5s;
            }
        }
     
    `}
`

export default Header
