import "swiper/css"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import styled, { css } from "styled-components"

import { CaretLeft } from "@styled-icons/boxicons-regular/CaretLeft"
import { CaretRight } from "@styled-icons/boxicons-regular/CaretRight"
import { animated_link } from "@css/global"
import { page_wrapper } from "@css/global"
import sanitizeHtml from "sanitize-html"

const EmployeesCarousel = props => {
  const Navigation = () => {
    return (
      <NavigationWrapper
        employeesCount={
          props?.employees?.edges?.length !== undefined
            ? props?.employees?.edges?.length
            : 0
        }
        className="employees-carousel__navigation"
      >
        <button
          onClick={() => {
            swiper && swiper.slideTo(activeIndex - 1)
          }}
          disabled={swiper && swiper?.isBeginning}
          aria-label="Navigate to the previous slide"
        >
          <CaretLeft />
        </button>
        <button
          onClick={() => {
            swiper && swiper.slideTo(activeIndex + 1)
          }}
          aria-label="Navigate to the next slide"
          disabled={swiper && swiper?.isEnd}
        >
          <CaretRight />
        </button>
      </NavigationWrapper>
    )
  }

  const [swiper, setSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <Wrapper {...props} className="employees-carousel">
      {!props.hasIntro && (
        <div className="employees-carousel__intro">
          {props?.blockIntro !== null && (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(props.blockIntro),
              }}
            ></div>
          )}
          <Navigation />
        </div>
      )}
      {props?.employees !== null && (
        <div className="employees-carousel__swiper">
          {props?.employees?.edges?.length && (
            <div className="employees-carousel__swiper-title">
              {props.carouselTitle && <h2>{props.carouselTitle}</h2>}
              <Navigation />
            </div>
          )}

          <Swiper
            onSwiper={setSwiper}
            onSlideChange={swiper => {
              setActiveIndex(swiper.activeIndex)
            }}
            slidesPerView={1}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
            }}
          >
            {props?.employees?.edges?.map((employee, index) => {
              return (
                <SwiperSlide key={index}>
                  {employee?.node?.featuredImage?.node !== null && (
                    <GatsbyImage
                      image={getImage(
                        employee?.node?.featuredImage?.node?.localFile
                      )}
                      alt={`An image of ${employee?.node?.title}`}
                    />
                  )}
                  {employee?.node?.featuredImage === null && (
                    <div className="employees-carousel__placeholder"></div>
                  )}
                  <h2>{employee?.node?.title}</h2>
                  <h3>{employee?.node?.employeesFields?.jobTitle}</h3>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      )}
    </Wrapper>
  )
}

const NavigationWrapper = styled.div`
  ${props => {
    return css`
      @media (min-width: 0em) {
        ${props.employeesCount <= 1 && `display:none `}
      }
      @media (min-width: 48em) {
        ${props.employeesCount <= 2 && `display:none `}
      }
      @media (min-width: 75em) {
        ${props.employeesCount <= 3 && `display:none `}
      }
    `
  }}

  display:flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
  button {
    &:disabled {
      svg {
        opacity: 0.4;
      }
    }

    &:first-of-type {
      margin-right: 0.25rem;
    }

    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    svg {
      height: 1.5em;
      color: white;
    }
  }
`

const Wrapper = styled.article`
  ${page_wrapper};

  display: grid;

  @media (min-width: 0em) {
    grid-gap: 1.5rem;
    grid-template-columns: minmax(0, 1fr);
  }
  @media (min-width: 75em) {
    grid-gap: 3rem;
    grid-template-columns: minmax(0, 1.5fr) minmax(0, 5fr);
  }

  .employees-carousel__intro {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @media (min-width: 48em) {
      text-align: right;
    }

    ${animated_link};

    a {
      font-size: 0.9375rem;

      @media (min-width: 48em) {
        font-size: 1.15rem;
      }
    }
  }

  .employees-carousel__swiper {
    .employees-carousel__swiper-title {
      display: none;

      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.8rem;

      h2 {
        font-size: 1.5rem;
      }
    }

    .swiper {
      max-width: 100%;

      .gatsby-image-wrapper,
      .employees-carousel__placeholder {
        height: 0;
        padding-bottom: 100%;
        margin-bottom: 1.5rem;
      }

      .employees-carousel__placeholder {
        background: grey;
      }

      h2 {
        font-size: 1.2rem;
        font-weight: 600;
      }
      h3 {
        font-size: 1.2rem;
      }
    }
  }
`

export default EmployeesCarousel
