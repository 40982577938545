import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { mid_blue } from "@css/colours"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { page_wrapper } from "@css/global"

const Offices = props => {
  const { allWpOffice } = useStaticQuery(graphql`
    query OfficesQuery {
      allWpOffice {
        edges {
          node {
            title
            officesFields {
              city
              documentExchange
              email
              fax
              phone
              postcode
              poBox
              state
              streetAddress
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper className="offices">
      <div className="offices__spacer"></div>

      <div className="offices__offices">
        {allWpOffice?.edges.map((office, index) => {
          const { officesFields } = office?.node
          return (
            <Accordion
              key={index}
              allowMultipleExpanded={false}
              allowZeroExpanded={true}
            >
              <AccordionItem>
                <address className="offices__office">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <p className="offices__office-title">
                        {office?.node?.title}
                      </p>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel>
                    {officesFields?.streetAddress !== null && (
                      <p>{officesFields?.streetAddress}</p>
                    )}

                    {officesFields?.poBox !== null && (
                      <p className="offices__office--pobox">
                        {officesFields?.poBox}
                      </p>
                    )}

                    {(officesFields?.city ||
                      officesFields?.state ||
                      officesFields?.postcode) && (
                      <p>
                        {officesFields.city && (
                          <span>{officesFields.city} </span>
                        )}
                        {officesFields.state && (
                          <span>{officesFields.state} </span>
                        )}
                        {officesFields.postcode && (
                          <span>{officesFields.postcode}</span>
                        )}
                      </p>
                    )}

                    {officesFields?.phone !== null && (
                      <a
                        className="offices__office--phone"
                        href={`tel:${officesFields?.phone?.replace(
                          /\s+/g,
                          ""
                        )}`}
                      >
                        <span>Phone </span>
                        <span>{officesFields?.phone}</span>
                      </a>
                    )}

                    {officesFields?.fax !== null && (
                      <a
                        className="offices__office--fax"
                        href={`tel:${officesFields?.fax?.replace(/\s+/g, "")}`}
                      >
                        <span>Fax </span>
                        <span>{officesFields?.fax}</span>
                      </a>
                    )}

                    {officesFields?.email !== null && (
                      <a
                        className="offices__office--email"
                        href={`mailto:${officesFields?.email}`}
                      >
                        <span>{officesFields?.email}</span>
                      </a>
                    )}

                    {officesFields.documentExchange !== null && (
                      <p className="offices__office--exchange">
                        {officesFields.documentExchange}
                      </p>
                    )}
                  </AccordionItemPanel>
                </address>
              </AccordionItem>
            </Accordion>
          )
        })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.article`
  ${page_wrapper};

  display: grid;
  @media (min-width: 0em) {
    grid-template-columns: minmax(0, 1fr);
  }
  @media (min-width: 75em) {
    grid-template-columns: minmax(0, 1.5fr) minmax(0, 5fr);
    grid-gap: 3rem;
  }
  @media (min-width: 87.5em) {
    grid-template-columns: minmax(0, 1fr);
  }

  * {
    font-style: normal;
  }

  .offices__spacer {
    @media (min-width: 87.5em) {
      display: none;
    }
  }

  .offices__offices {
    display: flex;
    flex-wrap: wrap;

    .accordion {
      &:not(:last-of-type) {
        @media (min-width: 87.5em) {
          margin-right: 3rem;
        }
      }

      @media (min-width: 0em) {
        width: 100%;
        margin-bottom: 0.5rem;
      }
      @media (min-width: 87.5em) {
        width: auto;
        margin-bottom: 0;
        .accordion__panel {
          display: block !important;
        }
      }

      .accordion__panel {
        @media (max-width: 84.999em) {
          padding: 1.5rem 0;
        }
      }

      .accordion__button {
        cursor: pointer;
        .offices__office-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.25rem;
          color: ${mid_blue};
          @media (min-width: 0em) {
            border-bottom: 1px solid ${mid_blue};
          }
          @media (min-width: 87.5em) {
            border-bottom: 0;
            margin-bottom: 1.5rem;
          }
          font-weight: 300;
        }

        @media (max-width: 87.499em) {
          &[aria-expanded="false"] {
            .offices__office-title {
              &:after {
                content: "+";
              }
            }
          }
          &[aria-expanded="true"] {
            .offices__office-title {
              &:after {
                content: "-";
              }
            }
          }
        }
      }

      p,
      a {
        font-size: 1.5rem;
        display: block;
        line-height: 1.4;

        &.offices__office--phone {
          margin-top: 0.75rem;
        }
      }

      a {
        transition: color 0.4s;
        &:hover {
          color: ${mid_blue};
          transition: color 0.4s;
        }
      }

      .offices__office--exchange {
        margin-top: 1rem;
        font-size: 0.75rem;
      }
    }
  }
`

export default Offices
